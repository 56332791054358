import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDvO7ysJnquveDMG-A8hCp2WJxkQRtyT8E",
  authDomain: "soppy-pet.firebaseapp.com",
  projectId: "soppy-pet",
  storageBucket: "soppy-pet.appspot.com",
  messagingSenderId: "324917484472",
  appId: "1:324917484472:web:c32660dd4e3b3ac30f0d4d",
  measurementId: "G-ZVZ8PJDPWT",
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const getAllDocs = (productType, listSetter) => {
  getDocs(collection(firestore, productType)).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      listSetter((prev) => [...prev, { id: doc.id, ...doc.data() }]);
    });
  });
};
export const addToFirestore = async (coll, data) => {
  const docRef = await addDoc(collection(firestore, coll), data);
  return docRef.id;
};
