import "./HeaderItem.css";
import React from "react";
import { Link } from "react-router-dom";

function HeaderItem({ additionalClass, text, link, setDrawer }) {
  return (
    <Link onClick={setDrawer ? () => setDrawer(false) : ""} className={"headerItem " + (additionalClass ? additionalClass : "")} to={link}>
      {text}
    </Link>
  );
}

export default HeaderItem;
