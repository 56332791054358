export const discountCondition = (basket) => {
  return false;
  // let basketQuantity = basket.reduce((acc, curr) => acc + curr.quantity, 0);
  // let pairsCount = basket.filter((el) => el.selectedPackage.toLowerCase().includes("komplet")).reduce((acc, curr) => acc + curr.quantity, 0);

  // return basketQuantity + pairsCount >= 4;
};

export const applyPairDiscount = (basket) => {
  let containsPair = false;

  // reset previous discount
  const previouslyDiscountedArr = basket.filter((elem) => elem.title.includes("(OSTVAREN POPUST)"));

  if (previouslyDiscountedArr.length > 0) {
    const previouslyDiscountedElem = previouslyDiscountedArr[0];

    const elemWithoutDiscountArr = basket.filter((elem) => elem.title === previouslyDiscountedElem.title.replace(" (OSTVAREN POPUST)", ""));
    if (elemWithoutDiscountArr.length > 0) {
      const indexToRemove = basket.indexOf(previouslyDiscountedElem);
      basket.splice(indexToRemove, 1);

      const elemWithoutDiscountElem = elemWithoutDiscountArr[0];
      elemWithoutDiscountElem.quantity++;
    } else {
      previouslyDiscountedElem.title = previouslyDiscountedElem.title.replace(" (OSTVAREN POPUST)", "");
      previouslyDiscountedElem.priceSrb = +previouslyDiscountedElem.priceSrb * 2;
      previouslyDiscountedElem.priceOther = +previouslyDiscountedElem.priceOther * 2;
    }
  }

  basket.forEach((elem) => {
    if (elem.title.includes("+")) containsPair = true;
  });

  if (!containsPair) return;

  const discountCandidates = basket.filter(
    (elem) => elem.title.includes("MIST PURE") || elem.title.includes("DETANGLING SPRAY") || elem.title.includes("PAW & NOSE BALM")
  );
  if (discountCandidates.length === 0) return;

  const elemToDiscount = discountCandidates.reduce((prev, curr) => {
    return +prev.priceSrb < +curr.priceSrb ? prev : curr;
  });

  // remove element to discount and add discounted one
  const indexToRemove = basket.indexOf(elemToDiscount);
  basket.splice(indexToRemove, 1);

  const discountedElem = { ...elemToDiscount };
  discountedElem.quantity = 1;
  if (!discountedElem.title.includes("(OSTVAREN POPUST)")) discountedElem.title += " (OSTVAREN POPUST)";
  discountedElem.priceSrb = +discountedElem.priceSrb / 2;
  discountedElem.priceOther = +discountedElem.priceOther / 2;

  elemToDiscount.quantity--;
  if (elemToDiscount.quantity > 0) basket.push(elemToDiscount);
  basket.push(discountedElem);
};
